
















































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { getComponent, sleep } from '@/utils/helpers';
// import { preloadData } from '@/utils/preloadOfflineData';
import {
    preloadData,
} from '@/utils/preloadOfflineData'
import accountModule from '@/store/modules/accountModule';
import operationsModule from '@/store/modules/operationsModule';
import { DateTime } from 'luxon';
import tasqsListModule from '@/store/modules/tasqsListModule';
import workflowModule from '@/store/modules/workflowModule';
import { ALL_TASQS_LIST_ITEM } from '@/lib/constants';
import { getView } from '@/utils/helpers';
import { getNameByEmail } from '@/utils/users';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    DoughnutChart: () => import('@/lib/charts/doughnutChart'),
    StatusPieCharts: () => getComponent('operations/StatusPieCharts'),
    TasqsDetails: () => getComponent('tasqs/TasqsDetails'),
    TasqsMobile: () =>  getComponent('tasqs/TasqDetailMobile'),
  },
})
export default class Home extends Vue {
    firstPieChartData: any[] = []

    secondPieChartData: any[] = []

    thirdPieChartData: any[] = []

    tasqsPerStatusData = []

    reportingStatsFilter = 'Today';

    tasqsPerRouteData = []

    showModal = false

    tasqsPerRoleData = []

    topDefermentCards = []

    topFailureCards = []

    totalPieChartTasqs = 0

    pieChartTitle = ''

    plannedWorkSectionOptions = [
      'All',
    //   'Workover handovers',
    //   'PMs',
    //   'New wells',
    //   'Projects',
    //   'Gauges',
    ]

    mapPredictionType = {
      Anomaly: 'Anomaly',
      'state change': 'Anomaly',
      'Prolonged Anomaly': 'Anomaly',
      'Off-Target': 'Off-Target',
	  'Off-Target RT': 'Real-Time',
      Workflow: 'Workflow',
      Prediction: 'Prediction',
      'No Comms': 'No Comms',
    }

    mapRoleType = {
      FieldOperator: 'Operators',
      Operator: 'Operators',
      Wireline: 'Wireline',
      Engineering: 'Engineering',
      Engineers: 'Engineering',
      Workover: 'Workover',
      Automation: 'Automation',
      Optimizers: 'Optimizers',
    }

    showFirstPieChart = true

    showSecondPieChart = false

    showThirdPieChart = false

    showTalliesPopupShowing = true

    tasqsSummarySectionOption = 'tasqs per status'

    dataLoading = false

    windowWidth = window.innerWidth;

plannedWorkDataAll = [
  {
    id: 1,
    day_of_week: 'Monday',
    completion_percentage: 0,
    date_string: '',
    tasq_count: 0,
    new_wells: {
      count: 0,
      wells: [],
    },
  },
  {
    id: 2,
    day_of_week: 'Tuesday',
    completion_percentage: 0,
    date_string: '',
    tasq_count: 0,
    new_wells: {
      count: 0,
      wells: [],
    },
  },	{
    id: 3,
    day_of_week: 'Wednesday',
    completion_percentage: 0,
    date_string: '',
    tasq_count: 0,
    new_wells: {
      count: 0,
      wells: [],
    },
  },	{
    id: 4,
    day_of_week: 'Thursday',
    completion_percentage: 0,
    date_string: '',
    tasq_count: 0,
    new_wells: {
      count: 0,
      wells: [],
    },
  },	{
    id: 5,
    day_of_week: 'Friday',
    completion_percentage: 0,
    date_string: '',
    tasq_count: 0,
    new_wells: {
      count: 0,
      wells: [],
    },
  }]


  nameByEmail(email) {
	  return getNameByEmail(email)
  }

  tasqsPerStatusTapped() {
    this.tasqsSummarySectionOption = 'tasqs per status';
  }

  tasqsPerRouteTapped() {
    this.tasqsSummarySectionOption = 'tasqs per route';
  }

  tasqsPerRoleTapped() {
    this.tasqsSummarySectionOption = 'tasqs per role';
  }

    showOperationsChartsVal = false

    hideOperationsCharts() {
      this.showOperationsChartsVal = false;
    }

      scroll_left() {
      let content = document.getElementById("wrapper-box")!;
      content.scrollLeft -= 200;
    }
    
    scroll_right() {
      let content = document.getElementById("wrapper-box")!;
      content.scrollLeft += 200;
    }

     scroll_leftReason() {
      let content = document.getElementById("wrapper-box-2")!;
      content.scrollLeft -= 200;
    }
    
    scroll_rightReason() {
      let content = document.getElementById("wrapper-box-2")!;
      content.scrollLeft += 200;
    }

    populatePieChartData(dataPieChart, x) {
      dataPieChart.count += 1;
      let updatedUsername = false;
      for (let w = 0; w < dataPieChart.data.length; w++) {
        if (dataPieChart.data[w].username == this.totalTasqsListArrayData[x].Username.toLowerCase()) {
          dataPieChart.data[w].total_tasqs += 1;
          updatedUsername = true;
        }
      }
      if (!updatedUsername) {
        dataPieChart.data.push({
          username: this.totalTasqsListArrayData[x].Username.toLowerCase(),
          total_tasqs: 1,
        });
      }
      return dataPieChart;
    }

    showOperationsCharts(selection) {
      this.firstPieChartData = [];
      if (this.tasqsSummarySectionOption == 'tasqs per status') {
        this.pieChartTitle = selection.title;
        var incompleteDataPieChartData: any[] = [];
        var totalJobCount = 0;
        var incompleteDataPieChart = {
          count: 0,
          total: 0,
          name: 'Incomplete',
          color: '#ff5e25',
          id: 0,
          data: incompleteDataPieChartData,
        };
        var reassignedDataPieChartData: any[] = [];
        var reassignedDataPieChart = {
          count: 0,
          total: 0,
          name: 'Reassigned',
          color: '#006cff',
          id: 1,
          data: reassignedDataPieChartData,
        };
        var snoozedDataPieChartData: any[] = [];
        var snoozedDataPieChart = {
          count: 0,
          total: 0,
          name: 'Snoozed',
          color: '#ffd001',
          id: 2,
          data: snoozedDataPieChartData,
        };
        var completeDataPieChartData: any[] = [];
        var completeDataPieChart = {
          count: 0,
          total: 0,
          name: 'Complete',
          color: '#4cdf95',
          id: 3,
          data: completeDataPieChartData,
        };
        for (var x = 0; x < this.totalTasqsListArrayData.length; x++) {
          let predictionType = this.totalTasqsListArrayData[x].PredictionType;
          if (predictionType == 'state change' || predictionType == 'Prolonged Anomaly') {
            predictionType = 'Anomaly';
          }
          if (predictionType == 'Off-Target RT'){
            predictionType = 'Real-Time';
          }
          if (predictionType == selection.title) {
            totalJobCount += 1;
            var start = new Date();
            start.setHours(0, 0, 0, 0);
            if ((this.totalTasqsListArrayData[x].Closed != null && this.totalTasqsListArrayData[x].Closed) || (new Date(`${this.totalTasqsListArrayData[x].CompletedForToday}.000Z`) > start)) {
              completeDataPieChart = this.populatePieChartData(completeDataPieChart, x);
            } else {
              incompleteDataPieChart = this.populatePieChartData(incompleteDataPieChart, x);
            }
          }
        }

        incompleteDataPieChart.total = totalJobCount;
        completeDataPieChart.total = totalJobCount;
        this.totalPieChartTasqs = totalJobCount;

        this.firstPieChartData.push(incompleteDataPieChart);
        this.firstPieChartData.push(completeDataPieChart);
      } else if (this.tasqsSummarySectionOption == 'tasqs per route') {
        this.pieChartTitle = selection.title;
        var incompleteDataPieChartData: any[] = [];
        var totalJobCount = 0;
        var incompleteDataPieChart = {
          count: 0,
          total: 0,
          name: 'Incomplete',
          color: '#ff5e25',
          id: 0,
          data: incompleteDataPieChartData,
        };
        var reassignedDataPieChartData: any[] = [];
        var reassignedDataPieChart = {
          count: 0,
          total: 0,
          name: 'Reassigned',
          color: '#006cff',
          id: 1,
          data: reassignedDataPieChartData,
        };
        var snoozedDataPieChartData: any[] = [];
        var snoozedDataPieChart = {
          count: 0,
          total: 0,

          name: 'Snoozed',
          color: '#ffd001',
          id: 2,
          data: snoozedDataPieChartData,
        };
        var completeDataPieChartData: any[] = [];
        var completeDataPieChart = {
          count: 0,
          total: 0,
          name: 'Complete',
          color: '#4cdf95',
          id: 3,
          data: completeDataPieChartData,
        };
        for (var x = 0; x < this.totalTasqsListArrayData.length; x++) {
          if (this.totalTasqsListArrayData[x].Route == selection.title) {
            totalJobCount += 1;
            var start = new Date();
            start.setHours(0, 0, 0, 0);
            if ((this.totalTasqsListArrayData[x].Closed != null && this.totalTasqsListArrayData[x].Closed) || (new Date(`${this.totalTasqsListArrayData[x].CompletedForToday}.000Z`) > start)) {
              completeDataPieChart = this.populatePieChartData(completeDataPieChart, x);
            } else {
              incompleteDataPieChart = this.populatePieChartData(incompleteDataPieChart, x);
            }
          }
        }

        incompleteDataPieChart.total = totalJobCount;
        completeDataPieChart.total = totalJobCount;
        this.totalPieChartTasqs = totalJobCount;
        this.firstPieChartData.push(incompleteDataPieChart);
        this.firstPieChartData.push(completeDataPieChart);
      } else if (this.tasqsSummarySectionOption == 'tasqs per role') {
        this.pieChartTitle = selection.title;
        var incompleteDataPieChartData: any[] = [];
        var totalJobCount = 0;
        var incompleteDataPieChart = {
          count: 0,
          total: 0,
          name: 'Incomplete',
          color: '#ff5e25',
          id: 0,
          data: incompleteDataPieChartData,
        };
        var reassignedDataPieChartData: any[] = [];
        var reassignedDataPieChart = {
          count: 0,
          total: 0,
          name: 'Reassigned',
          color: '#006cff',
          id: 1,
          data: reassignedDataPieChartData,
        };
        var snoozedDataPieChartData: any[] = [];
        var snoozedDataPieChart = {
          count: 0,
          total: 0,
          name: 'Snoozed',
          color: '#ffd001',
          id: 2,
          data: snoozedDataPieChartData,
        };
        var completeDataPieChartData: any[] = [];
        var completeDataPieChart = {
          count: 0,
          total: 0,
          name: 'Complete',
          color: '#4cdf95',
          id: 3,
          data: completeDataPieChartData,
        };
        for (var x = 0; x < this.totalTasqsListArrayData.length; x++) {
          if (this.mapRoleType[this.totalTasqsListArrayData[x].Role] == selection.title || this.totalTasqsListArrayData[x].Role == selection.title) {
            totalJobCount += 1;
            var start = new Date();
            start.setHours(0, 0, 0, 0);
            if ((this.totalTasqsListArrayData[x].Closed != null && this.totalTasqsListArrayData[x].Closed) || (new Date(`${this.totalTasqsListArrayData[x].CompletedForToday}.000Z`) > start)) {
              completeDataPieChart = this.populatePieChartData(completeDataPieChart, x);
            } else {
              incompleteDataPieChart = this.populatePieChartData(incompleteDataPieChart, x);
            }
          }
        }

        incompleteDataPieChart.total = totalJobCount;
        completeDataPieChart.total = totalJobCount;
        this.totalPieChartTasqs = totalJobCount;
        this.firstPieChartData.push(incompleteDataPieChart);
        this.firstPieChartData.push(completeDataPieChart);
      }
      this.showOperationsChartsVal = true;
    }

    plannedWorkSectionOptionSelected = 'All'

    get getLastUpdatedString(): any {
      return DateTime.now().toFormat('ff');
    }

    setActiveTasqDialog(tasq){
      this.showModal = false;
     let findOffTargetTasq = tasqsListModule.allTasqs.find(data => {
        return data.wellName === tasq.wellName && data.predictionType === `Off-Target`
      })
      if(findOffTargetTasq){
        tasqsListModule.setShowTasqDetailsMobile(true);
         tasqsListModule.setActiveTasq(findOffTargetTasq.id || '');
        tasqsListModule.setKanbanSelectedTasq(findOffTargetTasq.id || '');
        this.showModal = true;
      }
    }

    get getFirstPieChartData(): any {
      const colors = ['#ff5e25', '#006cff', '#ffd001', '#4cdf95', '#303279'];
      const names_array: any[] = [];
      const counts_array: any[] = [];
      const colors_array: any[] = [];
      const result = { names: names_array, counts: counts_array, colors: colors_array };
      for (let x = 0; x < this.firstPieChartData.length; x++) {
        result.names.push(this.firstPieChartData[x].name);
        result.counts.push(this.firstPieChartData[x].count);
        result.colors.push(this.firstPieChartData[x].color);
      }
      return result;
    }

    // get getSecondPieChartData(): any {
    //     var colors = ['#ff5e25', '#07dacc', '#ffd001']
    //     var names_array: any[] = []
    //     var counts_array: any[] = []
    //     var colors_array: any[] = []
    //     var result = {"names": names_array, "counts": counts_array, "colors": colors_array}
    //     for (var x = 0; x < this.secondPieChartData.length; x++) {
    //         result.names.push(this.secondPieChartData[x].name)
    //         result.counts.push(this.secondPieChartData[x].count)
    //         result.colors.push(colors[x % colors.length])

    //     }
    //     return result;
    // }

    // get getThirdPieChartData(): any {
    //     var colors = ['#FF9259', '#FFCE00', '#FF5D25', '#4cdf95', '#303279']
    //     var names_array: any[] = []
    //     var counts_array: any[] = []
    //     var colors_array: any[] = []
    //     var result = {"names": names_array, "counts": counts_array, "colors": colors_array}
    //     for (var x = 0; x < this.thirdPieChartData.length; x++) {
    //         result.names.push(this.thirdPieChartData[x].name)
    //         result.counts.push(this.thirdPieChartData[x].count)
    //         result.colors.push(colors[x % colors.length])

    //     }
    //     return result;
    // }

    get totalTasqsListArrayData(): any {
      return operationsModule.totalTasqsListArray;
    }

    get tasqsPerRoleArrayData(): any {
      return operationsModule.tasqsPerRoleArray;
    }

    get tasqsPerRouteArrayData(): any {
      return operationsModule.tasqsPerRouteArray;
    }

    get tasqsPerPredictionTypeArrayData(): any {
      return operationsModule.tasqsPerPredictionTypeArray.filter((p) => p.title.toLowerCase() !== 'anomaly' &&  p.title.toLowerCase() !== 'prolonged anomaly'    );
    }

    get getPredictionTalliesOptData(): any {
      return operationsModule.predictionTalliesDict;
    }

    get getUpliftVal(): any {
      return operationsModule.uplift;
    }

    get getOptimizedVal(): any {
      return operationsModule.optimized;
    }

    get getSetpointTotalVal(): any {
      return operationsModule.total;
    }

    get tasqList(): any {
      return tasqsListModule.tasqList;
    }

	get waitingTasqList(): any {
		return tasqsListModule.waitingTasqList;
	}

    get getTopDefermentArray(): any {
      const results: any[] = [];
      for (let y = 0; y < operationsModule.topDefermentArray?.length; y++) {
        let did_add_username = false;
        for (let x = 0; x < this.tasqList.length; x++) {
          // @ts-ignore
          if (operationsModule.topDefermentArray[y].wellName == this.tasqList[x].wellName) {
            // @ts-ignore
            operationsModule.topDefermentArray[y].assignee = this.tasqList[x].username.toLowerCase();
            results.push(operationsModule.topDefermentArray[y]);
            did_add_username = true;
          }
        }
		if (!did_add_username) {
			for (let x = 0; x < this.waitingTasqList.length; x++) {
				// @ts-ignore
				if (operationsModule.topDefermentArray[y].wellName == this.waitingTasqList[x].wellName) {
					// @ts-ignore
					operationsModule.topDefermentArray[y].assignee = this.waitingTasqList[x].username.toLowerCase();
					results.push(operationsModule.topDefermentArray[y]);
					did_add_username = true;
				}
			}
		}
        if (!did_add_username) {
          results.push(operationsModule.topDefermentArray[y]);
        }
      }
      return results;
    }

    get getDefermentTotal(): any {
      return operationsModule.defermentTotal;
    }

    get getDefermentUnits(): any {
      return operationsModule.units;
    }

     assigneeNameByEmail(email): any {
      const assigneeDetails: any = accountModule.reassignmentList.find((i) => i.email === email);
      if (assigneeDetails) {
        return assigneeDetails.name;
      }
      return email;
    }

     closeDialog(){
      this.showModal = false;
    }

    @Watch('isMobile')
    onChangeIsMobile(value){
      let el = document.getElementById('top-vertical-setting')!
      if(value){
       el.style.display = 'none';
      }else {
        el.style.display = 'block';
      }
    }

    beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    tasqsListModule.setActivePage('');
    tasqsListModule.setActiveTasq('')
    tasqsListModule.setKanbanSelectedTasq('')
  }

   get isMobile() {
     return this.windowWidth < 769;
   }

   mounted() {
     this.$nextTick(() => {
       window.addEventListener('resize', this.onResize);
     });
   }

   onResize() {
     this.windowWidth = window.innerWidth;
   }

     sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

  get getReportStatsDowntimeReason(){
	  var filtered = workflowModule.downtimeByReason.filter(reason => {
        return true
    })

	function compare( a, b ) {
		if ( a.value.Total < b.value.Total ){
			return 1;
		}
		if ( a.value.Total > b.value.Total ){
			return -1;
		}
		return 0;
	}

	filtered.sort( compare );
	var updated_list: any[] = []
	var categorized: any = null
	for (var x = 0; x < filtered.length; x++) {
		if (filtered[x].key == 'Uncategorized') {
			categorized = filtered[x]
		} else {
			updated_list.push(filtered[x])
		}
	}
	if (categorized != null) {
		updated_list.push(categorized)
	}

    return updated_list
  }


  get getReportingStats(){
    let reportingStats = workflowModule.reportingStats

    console.log(reportingStats)

      let returnResult = {
        'actionedCompleted': 0,
        'scheduled': 0,
        'responded': 0,
        'totalPercentage': 0,
      }

    if(this.reportingStatsFilter === 'Today' && reportingStats && reportingStats['Total']['Today'] ){
      let totalToday = reportingStats['Total']['Today']
      console.log('hi')
      console.log(totalToday)
      let actionedCompleted = reportingStats['Actioned']['Today']
      let responed = reportingStats['Responded']['Today']
      let scheduled = reportingStats['Scheduled']['Today']
      if(totalToday && actionedCompleted){
        returnResult['actionedCompleted'] = Math.floor((actionedCompleted/ totalToday)*100)
      }

       if(totalToday && responed){
        returnResult['responed'] = Math.floor((responed/ totalToday)*100)
      }

      
       if(totalToday &&  scheduled){
        returnResult['scheduled'] = Math.floor(( scheduled / totalToday)*100)
      }

      let sumTodayActions = returnResult['responded'] + returnResult['scheduled'] + returnResult['actionedCompleted']
      if(sumTodayActions){
        returnResult['totalPercentage'] = sumTodayActions
      }

      console.log(returnResult)
      
    }else if(this.reportingStatsFilter === 'Yesterday' && reportingStats && reportingStats['Total']['Yesterday'] ){
      let totalToday = reportingStats['Total']['Yesterday']
      let actionedCompleted = reportingStats['Actioned']['Yesterday']
      let responed = reportingStats['Responded']['Yesterday']
      let scheduled = reportingStats['Scheduled']['Yesterday']
      if(totalToday && actionedCompleted){
        returnResult['actionedCompleted'] = Math.floor((actionedCompleted/ totalToday)*100)
      }

       if(totalToday && responed){
        returnResult['responed'] = Math.floor((responed/ totalToday)*100)
      }

      
       if(totalToday &&  scheduled){
        returnResult['scheduled'] = Math.floor(( scheduled / totalToday)*100)
      }

      let sumTodayActions = returnResult['responded'] + returnResult['scheduled'] + returnResult['actionedCompleted']
      if(sumTodayActions){
        returnResult['totalPercentage'] = sumTodayActions
      }
      
    }
    return returnResult;
  }

    async created() {

      await operationsModule.getPlannedWork({})


	  for (var x = 0; x < this.plannedWorkDataAll.length; x++) {
		
		  if (this.plannedWorkDataAll[x].day_of_week == 'Monday') {
			  this.plannedWorkDataAll[x].tasq_count = operationsModule.plannedWorkDict["Monday"].length
			  this.plannedWorkDataAll[x].new_wells.count = operationsModule.plannedWorkDict["Monday"].length
			  this.plannedWorkDataAll[x].new_wells.wells = operationsModule.plannedWorkDict["Monday"]
		  } else if (this.plannedWorkDataAll[x].day_of_week == 'Tuesday') {
			  this.plannedWorkDataAll[x].tasq_count = operationsModule.plannedWorkDict["Tuesday"].length
			  this.plannedWorkDataAll[x].new_wells.count = operationsModule.plannedWorkDict["Tuesday"].length
			  this.plannedWorkDataAll[x].new_wells.wells = operationsModule.plannedWorkDict["Tuesday"]	

		  } else if (this.plannedWorkDataAll[x].day_of_week == 'Wednesday') {
			  this.plannedWorkDataAll[x].tasq_count = operationsModule.plannedWorkDict["Wednesday"].length
			  this.plannedWorkDataAll[x].new_wells.count = operationsModule.plannedWorkDict["Wednesday"].length	 
			  this.plannedWorkDataAll[x].new_wells.wells = operationsModule.plannedWorkDict["Wednesday"] 
		  } else if (this.plannedWorkDataAll[x].day_of_week == 'Thursday') {
			  this.plannedWorkDataAll[x].tasq_count = operationsModule.plannedWorkDict["Thursday"].length
			  this.plannedWorkDataAll[x].new_wells.count = operationsModule.plannedWorkDict["Thursday"].length	
			  this.plannedWorkDataAll[x].new_wells.wells = operationsModule.plannedWorkDict["Thursday"]  
		  } else if (this.plannedWorkDataAll[x].day_of_week == 'Friday') {
			  this.plannedWorkDataAll[x].tasq_count = operationsModule.plannedWorkDict["Friday"].length
			  this.plannedWorkDataAll[x].new_wells.count = operationsModule.plannedWorkDict["Friday"].length
			  this.plannedWorkDataAll[x].new_wells.wells = operationsModule.plannedWorkDict["Friday"]	  
		  }
	  }

// plannedWorkDataAll = [
//   {
//     id: 1,
//     day_of_week: 'Monday',
//     completion_percentage: 0,
//     date_string: '',
//     tasq_count: 0,
//     new_wells: {
//       count: 0,
//       wells: [],
//     },
//   },

      this.dataLoading = true;
      await workflowModule.getOppPercentage()
      
      accountModule.getReassignmentList();
      tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);
	  tasqsListModule.getWaitList()
	  await operationsModule.getPredictionTalliesOpt();
     tasqsListModule.setActivePage('Operations')

      const fromTime = DateTime.local().minus({ days: 1 }).toISO();
      const toTime = DateTime.local().toISO();

      operationsModule.getDefermentAggLatest({
        from_date: fromTime,
        to_date: toTime,
      });
      this.dataLoading = false;
	this.sleep(500)




		if (!accountModule.didPreloadData) {
			preloadData()
		}

    }

    toggle_row(id) {
      $(`#extra_${id}`).slideToggle();
    }
}

